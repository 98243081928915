import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/app-form/', { params })).data

    if (error) throw error

    return result
  },
  async downloadFile(params) {
    return axiosIns.post('/app-form/save-to-file', params, { responseType: 'blob', responseEncoding: 'utf8' })
  },
}
