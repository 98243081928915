import applicationFormRepository from '@/repository/applicationFormRepository'

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import ability from '@/libs/acl/ability'

export default function getElementList() {
  const Columns = [
    { key: 'organization', label: 'Организация' },
    { key: 'name', label: 'ФИО', sortable: true },
    { key: 'city', label: 'Город', sortable: true },
    { key: 'phone', label: 'Номер телефона', sortable: true },
    { key: 'email', label: 'E-mail', sortable: true },
    { key: 'typeParticipation', label: 'Формат сотрудничества', sortable: true },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
  ]

  const refApplicationForm = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const period = ref()

  const dataMeta = computed(() => {
    const localItemsCount = refApplicationForm.value ? refApplicationForm.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  if (!ability.can('read', 'ApplicationFormWebsite')) {
    store.commit('app/SET_ERROR', 'Недостаточно прав для чтения')
  }

  const refetchData = () => {
    refApplicationForm.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    applicationFormRepository.elementList({
      query: searchQuery.value ? searchQuery.value : null,
      period: period.value ? period.value.split(' — ') : null,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refApplicationForm,
    Columns,
    period,
    refetchData,

  }
}
