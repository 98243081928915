<template>
  <div class="text-center">
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      size="sm"
      variant="primary"
      @click="downloadFile()"
    >
      <feather-icon
        icon="FileIcon"
        size="12"
      /><small class="p-50">скачать</small>
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import applicationFormRepository from '@/repository/applicationFormRepository'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    searchQuery: {
      type: String,
      default: null,
    },
    sortBy: {
      type: String,
      default: 'id',
    },
    isSortDirDesc: {
      type: Boolean,
      default: true,
    },
    period: {
      type: String,
      default: '',
    },
  },
  methods: {
    async downloadFile() {
      try {
        const response = (await applicationFormRepository.downloadFile({
          query: this.searchQuery ?? null,
          period: this.period ? this.period.split(' — ') : null,
          sord: this.isSortDirDesc ? 'desc' : 'asc',
          sidx: this.sortBy,
        })) ?? {}
        const { data, headers } = response

        const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }))
        const link = document.createElement('a')
        link.href = url
        let fileName = 'unknown'

        if (headers['content-disposition']) {
          const fileNameMatch = headers['content-disposition'].match(/filename="(.+)"/)
          if (fileNameMatch !== null && fileNameMatch.length === 2) {
            fileName = decodeURI(fileNameMatch[1])
          }
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        this.$store.commit('app/SET_ERROR', 'Ошибка загрузки файла')
      }
    },
  },
}
</script>

<style>

</style>
